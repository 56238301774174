@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Open+Sans:wght@300;400&family=Tenor+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Questrial&family=Tenor+Sans&display=swap");

body {
  overscroll-behavior: none;
}

body h2 {
  font-size: 2.5rem;
}

body a {
  text-decoration: none;
  color: #9fb15d;
}

body a:hover {
  color: #6c783e;
  cursor: pointer;
}

.App {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: #293400;
  background-color: #f6ffd6;
}

.nav-bar {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  background-color: #f6ffd6;
  z-index: 10;
  padding-right: 15px;
}

.nav-bar a {
  float: right;
  display: block;
  color: #293400;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.page-container {
  padding-top: 80px;
}

.blog-container {
  padding-top: 80px;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 40px;
}

.frontpage-container {
  display: flex;
  padding-top: 110px;
  padding-bottom: 150px;
}

.frontpage-child {
  flex: 1;
  margin: 5px;
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
  padding-right: 10px;
}

.frontpage-child h1 {
  font-size: 12rem;
  text-align: right;
  font-family: "Tenor Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.frontpage-child p {
  padding-left: 120px;
  padding-bottom: 35px;
}

.about-container {
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 80px;
  display: flex;
  margin-bottom: 40px;
}

.about-container li {
  list-style-type: none;
  display: inline-block;
  padding-left: 30px;
}

.about-container div:nth-child(2) {
  margin-top: 575px;
  max-width: 410px;
}

.about-container-child {
  flex: 1;
  text-align: right;
}

.headshot-container {
  display: flex;
  justify-content: center;
}

.about-container-child img {
  border-radius: 50%;
  max-width: fit-content;
  height: 550px;
  margin-bottom: 25px;
}

.about-container-child a {
  font-size: 1.25rem;
}

.contact-container {
  padding-top: 80px;
  text-align: center;
  padding-bottom: 100px;
}

.contact-container span {
  padding-left: 10px;
  padding-right: 10px;
}

.contact-social {
  padding-top: 30px;
}

.projects-container {
  padding-top: 80px;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 20px;
}

.projects-container h2 {
  max-width: 75%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.projects-carousel {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.youtube-embed {
  text-align: center;
  margin-bottom: -1px;
}

.carousel-caption {
  position: relative;
}

.project-caption {
  margin-top: 200px;
}

.project-caption h5 {
  color: #293400;
}

.project-caption p {
  color: #293400;
}

.blog-container h2 {
  padding-left: 30px;
}

.blog-container-child {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.blog-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 45%;
  padding-left: 40px;
  padding-right: 30px;
  padding-bottom: 10px;
  margin: 20px;
  padding-top: 20px;
  box-shadow: 7px 7px 5px #ebf4c7;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}

.blog-post a {
  font-size: 1.25rem;
}

.blog-post img {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.hamburger {
  display: none;
}

.menu {
  background-color: #f6ffd6;
  min-height: 100%;
  margin-top: -8px;
  margin-bottom: -8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

@media screen and (min-width: 1900px) {
  .App {
    padding-left: 50px;
    padding-right: 50px;
  }

  .nav-bar {
    padding-right: 120px;
  }

  .frontpage-child h1 {
    font-size: 13.5rem;
  }

  .about-container img {
    height: 700px;
  }

  .about-container div:nth-child(2) {
    margin-top: 725px;
  }
}

@media screen and (max-width: 1755px) {
  #nutripal {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 1638px) {
  .youtube-embed {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1600px) {
  p,
  a,
  li {
    font-size: 1.4rem;
  }

  .project-caption h5 {
    font-size: 1.75rem;
  }

  .blog-post a {
    font-size: 1.75rem;
  }

  .project-caption {
    margin-top: 250px;
  }
}

@media screen and (max-width: 1599px) {
  #nutripal {
    padding-bottom: 5px;
  }

  #forbes {
    padding-bottom: 5px;
  }

  .youtube-embed {
    padding-bottom: 1px;
  }
}

@media screen and (max-width: 1274px) {
  #nutripal {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1200px) {
  body {
    overscroll-behavior: none;
  }

  .frontpage-container {
    display: block;
    padding-top: 150px;
    padding-bottom: 5px;
  }

  .frontpage-child {
    justify-content: center;
  }

  .frontpage-child h1 {
    font-size: 7rem;
  }

  .frontpage-child p {
    padding: 5px;
  }

  .about-container {
    display: block;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 80px;
    margin-bottom: 40px;
  }

  .about-container-child {
    text-align: center;
  }

  .about-container-child p {
    padding-left: 20px;
    padding-right: 20px;
  }

  .about-container-child img {
    height: 400px;
    margin-bottom: 50px;
  }

  .about-container div:nth-child(2) {
    margin-top: 55px;
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
  }

  .about-container-child ul {
    padding-left: 0px;
  }

  .about-container-child li:nth-child(1) {
    padding-left: 0px;
  }

  .projects-carousel {
    max-width: 80%;
  }

  .projects-container h2 {
    max-width: 75%;
  }
}

@media screen and (max-width: 1120px) {
  .youtube-embed {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 1037px) {
  #forbes {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .frontpage-child h1 {
    font-size: 6rem;
  }
}

@media screen and (max-width: 869px) {
  .blog-container-child {
    justify-content: center;
  }

  .blog-container {
    text-align: center;
  }

  .blog-container h2 {
    padding-left: 0px;
  }

  .blog-post {
    max-width: 85%;
  }
}

@media screen and (max-width: 854px) {
  .frontpage-child h1 {
    font-size: 5.5rem;
  }

  #nutripal {
    padding-bottom: 54px;
  }
}

@media screen and (max-width: 785px) {
  .frontpage-child h1 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 768px) {
  .nav-bar {
    display: none;
  }

  .hamburger {
    font-size: 1.5rem;
    display: block;
    float: right;
    position: relative;
    padding-top: 20px;
    padding-right: 20px;
  }

  .hamburger li {
    list-style-type: none;
  }

  .frontpage-container {
    display: block;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .frontpage-child {
    text-align: center;
  }

  .frontpage-child h1 {
    font-size: 5.3rem;
    text-align: center;
    font-family: "Tenor Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .about-container {
    display: block;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 80px;
    margin-bottom: 40px;
  }

  .about-container-child {
    text-align: center;
  }

  .projects-container h2 {
    text-align: center;
    padding-left: 0px;
  }
}

@media screen and (max-width: 728px) {
  .youtube-embed {
    padding-bottom: 47px;
  }
}

@media screen and (max-width: 708px) {
  #forbes {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 707px) {
  #forbes {
    padding-bottom: 55px;
  }
}

@media screen and (max-width: 702px) {
  .projects-carousel {
    max-width: 85%;
  }

  #forbes {
    padding-bottom: 30px;
  }

  .youtube-embed {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 689px) {
  .youtube-embed {
    padding-bottom: 47px;
  }
}

@media screen and (max-width: 670px) {
  #forbes {
    padding-bottom: 53px;
  }
}

@media screen and (max-width: 627px) {
  #nutripal {
    padding-bottom: 75px;
  }
}

@media screen and (max-width: 568px) {
  .youtube-embed {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 546px) {
  #forbes {
    padding-bottom: 76px;
  }
}

@media screen and (max-width: 526px) {
  #nutripal {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 500px) {
  .frontpage-container {
    display: block;
    padding-top: 150px;
    padding-bottom: 100px;
  }

  .frontpage-child {
    text-align: center;
  }

  .frontpage-child h1 {
    font-size: 4rem;
    text-align: center;
    font-family: "Tenor Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .about-container-child img {
    height: 300px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 405px) {
  .about-container-child h2:nth-child(1) {
    font-size: 2rem;
  }
}

@media screen and (max-width: 486px) {
  .youtube-embed {
    padding-bottom: 95px;
  }
}

@media screen and (max-width: 460px) {
  #nutripal {
    padding-bottom: 124px;
  }
}

@media screen and (max-width: 456px) {
  #forbes {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 444px) {
  #forbes {
    padding-bottom: 125px;
  }
}

@media screen and (max-width: 418px) {
  .youtube-embed {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 390px) {
  #forbes {
    padding-bottom: 149px;
  }
}

@media screen and (max-width: 389px) {
  #nutripal {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 367px) {
  #nutripal {
    padding-bottom: 174px;
  }
}

@media screen and (max-width: 360px) {
  .youtube-embed {
    padding-bottom: 145px;
  }
}

@media screen and (max-width: 348px) {
  #forbes {
    padding-bottom: 175px;
  }

  .projects-container h2 {
    font-size: 2.3rem;
  }

  .contact-social p {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}
